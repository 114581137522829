<template>
  <component :is="layout" />
</template>

<script>


import AdminLte from "@/pages/_layouts/AdminLte";
import AdminLte2 from "@/pages/_layouts/AdminLte2";

import NoSidebar from "@/pages/_layouts/NoSidebar";
import VoidLayout from "@/pages/_layouts/VoidLayout";


export default {
  components: {
    AdminLte,
    AdminLte2,
    NoSidebar,
    VoidLayout,
  },
  data() {
    return {
      layout: null,
    };
  },
  watch: {
    $route(to) {
      // set layout by route meta
      //console.log("layout->"+ to.meta.layout);
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout;
      } else {
        this.layout = "VoidLayout"; // this is default layout if route meta is not set
      }
    },
  },

}
</script>

<style>

</style>
