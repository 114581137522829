<template>
  <div>
      
        <div class="content5">
          <h1> Bienvenido al sistema</h1>
          <p>Nidera</p>
          
          <hr style="margin: 50px 0px; border: 1px solid rgb(227, 227, 227);">
        </div>
      
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
        
        menu: [
          {
            header: 'Main Navigation',
            hiddenOnCollapse: true
          },
          {
            href: '/',
            title: 'Dashboard',
            icon: 'fa fa-user'
          },
          {
            href: '/charts',
            title: 'Charts',
            icon: 'fa fa-chart-area',
            child: [
              {
                href: '/charts/sublink',
                title: 'Sub Link'
              }
            ]
          }
        ]
      }

  }
}
</script>
