<template>
  <div>
 <header class="main-header">
    <!-- Logo -->
    <a href="index2.html" class="logo">
      <!-- mini logo for sidebar mini 50x50 pixels -->
      <span class="logo-mini"><b>NIDE</b>RA</span>
      <!-- logo for regular state and mobile devices -->
      <span class="logo-lg"><b>NIDE</b>RA</span>
    </a>
    <!-- Header Navbar: style can be found in header.less -->
    <nav class="navbar navbar-static-top">
      <!-- Sidebar toggle button-->
      <a href="#" class="sidebar-toggle" role="button" @click="showSidebar_click()">
        <i class="fa fa-bars"></i>
      </a>

      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <!-- Messages: style can be found in dropdown.less-->
          
          <!-- Notifications: style can be found in dropdown.less -->
         
          <!-- Tasks: style can be found in dropdown.less -->
          
          <!-- User Account: style can be found in dropdown.less -->
          <li class="dropdown user user-menu">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown5" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <img :src="ruta_file" class="user-image" alt="User">
              <span class="hidden-xs">{{ user.name }}</span>
            </a>

            <ul class="dropdown-menu" aria-labelledby="navbarDropdown5">
              <!-- User image -->
              <li class="user-header">
                <img :src="ruta_file" class="img-circle" alt="User Image">

                <p>
                  {{ user.name }}
                  <small>-</small>
                </p>
              </li>
              <!-- Menu Body -->
              <li class="user-body">
                <div class="row">
                  <div class="col-xs-4 text-center">
                    <a href="#">Followers</a>
                  </div>
                  <div class="col-xs-4 text-center">
                    <a href="#">Sales</a>
                  </div>
                  <div class="col-xs-4 text-center">
                    <a href="#">Friends</a>
                  </div>
                </div>
                <!-- /.row -->
              </li>
              <!-- Menu Footer-->
              <li class="user-footer">
                <div class="pull-left">
                  <router-link to="/perfil" class="btn btn-default btn-flat" >Perfil</router-link>
                </div>
                <div class="pull-right">
                  <a href="#" class="btn btn-default btn-flat" @click="logout">Salir</a>
                </div>
              </li>
            </ul>
          </li>
          <!-- Control Sidebar Toggle Button -->
         
        </ul>
      </div>
    </nav>
  </header>

    <!--<router-view></router-view>-->

  </div>

</template>

<script>
import { useStore } from 'vuex';
import router from "@/router";
import { ref, onMounted } from 'vue';

export default {
  components: {
    
  },
  setup() {
    const store = useStore(); //para vuex
    const user = ref({ name:''});
    const ruta_file = ref('');
    //---------------------------------------------------
    onMounted(() => {
        //console.log("onMounted");
        user.value = store.getters['auth/getUser'];
        ruta_file.value = process.env.VUE_APP_AFILES + user.value.foto_path1; //ruta de la foto
        //console.log(ruta_file.value);
      });
    //--------------------------------------------------
    function logout () {
      //localStorage.removeItem('tweetr-token')
      store.dispatch("auth/logout");
      
      router.push("/");
    }
    function showSidebar_click(){
      //const elem = document.getElementById("body00");
      document.getElementById("body00").classList.toggle('sidebar-open');
    }
    
    return{
      user,
      ruta_file,
      logout,
      showSidebar_click,
    }
  }
}
</script>

<style scoped>
.navbar{
    display: block;
    padding: 0;
}
.navbar a{
  line-height: 1.2;
}
ul.navbar-nav{
  flex-direction: inherit;
}
.navbar-custom-menu > .navbar-nav > li {
    position: relative;
}
.navbar-custom-menu .navbar-nav > li > a {
  line-height: 30px;
  position: relative;
  display: block;
  padding: 10px 15px;
}
</style>
