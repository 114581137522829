<template>
    <div >
        <!-- loading -->
        <div class="loading-mask " v-show="loading" v-bind:class="classes"  v-bind:style="{ backgroundColor:bc}">
            <div class="loading-circle"></div>
            <h4 class="loading-content">{{ text }}</h4>
        </div>
        <!-- -->
    </div>
</template>

<script>

//import { ref,reactive } from 'vue'
//import { onMounted } from 'vue'
import { computed} from 'vue' 

export default {
  components: {
  },
  props:{
      text:{ type: String, default: "Loading..."},
      dark:{ type: Boolean, default: true},
      loading:{ type: Boolean, default: false},
      classes:{ type: String, default: null},
      background:{ type: String, default: null}, //background-color
      
  },
  //setup(props, context) {
  setup(props) {

    //--- Metodos ------------ 
    const bc = computed(()=>{
      return props.background || ( props.dark ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)');
    });
    

    return {
        bc,    
    }
  }
  
}
</script>
<style scoped>

.loading-circle {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-left-color: #ababab;
  animation: circleanimation .45s linear infinite
}

@keyframes circleanimation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

    
</style>