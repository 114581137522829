<template>
  <div>
    <Modal :showModal="isModalVisible" 
           @closeModal="handleClose">
        <template v-slot:title>{{ title }}</template>
        <form
            autocomplete="off"
            @keydown="handleKeydown"
            @submit.prevent.stop="handleConfirm"
            class=" rounded-lg overflow-hidden"
        >
          <div>
              

              <p class="text-gray-800 mb-3">
                  {{ message }}
              </p>

              
          </div>

          <div class="bg-30 px-6 py-3 flex">
                <div class="flex text-end ml-auto">
                    
                    
                    <button
                        ref="runButton"
                        dusk="confirm-action-button"
                        :disabled="working"
                        type="submit"
                        class="btn btn-default btn-sm px-3 mr-3 pl-1"
                        :class="{
                            'btn-primary': action=='primary',
                            'btn-danger': action=='danger',
                        }"
                        style="margin-right:5px"
                    >
                    {{ button.yes}}
                        <!--<loader v-if="working" width="30"></loader>
                        <span v-else>{{ __('Run Action') }}</span>-->
                    </button>

                    <button
                        dusk="cancel-action-button"
                        type="button"
                        @click.prevent="handleClose"
                        class="btn btn-default btn-sm text-80 font-normal h-9 px-3 mr-3 pl-1 "
                        
                    >
                        {{ button.no }}
                    </button>
                </div>
            </div>
        </form>
    </Modal>
  </div>
</template>

<script>
import { ref,reactive, computed } from 'vue'
import { onMounted } from 'vue';
import Modal from "@/components/Common/Modal.vue";

export default {
  components: {
    Modal,
  },
  props: {
    title: {  type: String,  required: true, },
    message: {type: String, default:'¿Esta ud seguro?'},
    button: { type: Object, default:{  no: 'Cancelar',  yes: 'Aceptar'  }},
    action: { type: String, default:'primary'}
  },
  setup(props, {emit}){
    
    const isModalVisible = ref(false);
    const idd= ref(0);


    //console.log(props.modalActive);
    const handleKeydown=(e)=>{
      if (['Escape', 'Enter'].indexOf(e.key) !== -1) {
                return
      }
      e.stopPropagation()
    }
    //----------------------------------
    function show(id, mensaje) {
      //--evento hacia afuera
      console.log("show()");
      idd.value = id;
      isModalVisible.value = true;
    }
    //-----------------------------
    const handleClose = (n) => {
      isModalVisible.value = false;
      console.log("evento close");
      emit("close");
      /*if(n) {
        handleClearLocalstorage();
      }*/
    };
    //--------------------------
    const handleConfirm=()=>{
      console.log("handle confirm");
      isModalVisible.value = false;
      emit('confirm',idd.value)
    }
    //--------------------------

    return{
      idd,
      isModalVisible,
      handleKeydown,
      handleConfirm,
      show,
      handleClose,
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">

</style>
