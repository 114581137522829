<template>
  <div class="custom-select" >
    
    <select :value ="selectValue" 
            @change="onChangeOption($event)" 
            v-bind:class="clasex">
      
      <option v-for="(option, i) of options"   :key="i"
           v-bind:value="option.value"   
        >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>

//import { ref,reactive } from 'vue'
//import { onMounted } from 'vue'
import { ref,reactive,  onMounted } from 'vue'
import { computed} from 'vue' 

export default {

  components: {
  },
  props:{
      options:{type: Array, required: true},
      selectValue:{type: Number, required:false, default: 0},
      classes:{ type: String, default: null},
      clasex:{ type: String, default: null}, //background-color
      
  },
  // selectValue => current selected value
  // options => choices in the dropdown
  setup(props, context) {

    const open = ref(false);
    
    //--- Metodos ------------ 
    const bc = computed(()=>{
      return props.background || ( props.dark ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)');
    });
   

    //--- item.label, item.value
    function onChangeOption(e) {
        console.log(e.target.value);
        //---buscamos en el array, para que lo retornemos el row 
        const filtered = props.options.filter(item => {
        return item.value == e.target.value;
        });
        
        //---lo anexamos para ser retornado
        e.target.item = filtered[0];
        //console.log(e.target.item.label + "---" );

        context.emit('onChange',e.target.item);
    }
    return {
       bc,    
       open,
       onChangeOption,
    }
  }
  
}
</script>

<style scoped>


.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 47px;
  line-height: 47px;
}

.custom-select .selected {
  background-color: #0a0a0a;
  border-radius: 6px;
  border: 1px solid #666666;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border: 1px solid #ad8225;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid #ad8225;
  border-left: 1px solid #ad8225;
  border-bottom: 1px solid #ad8225;
  position: absolute;
  background-color: #0a0a0a;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #ad8225;
}

.selectHide {
  display: none;
}
</style>