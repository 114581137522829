import cApi from './cApi'

/* 
//Examples of ISO format: YYYY-MM-DD
  const fechax = new Date(value);
//compensar la diferencia por la zona horaria
  fechax.setMinutes(fechax.getMinutes() + fechax.getTimezoneOffset())
*/

class cUtilFecha {
  //--------calculamos la edad segun la fecha de nacimiento
  //let suma = cUtilFecha.calcEdad(); //edad = 12
  static edad(dateString) {
    let hoy = new Date()
    let fechaNacimiento = new Date(dateString)
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--
    }
    return edad
  }
  //-------------------------------------------------------------------
  static fechaHoy(){
    const today  = new Date();
    //today.setMinutes(today.getMinutes() + today.getTimezoneOffset())
    return today;
  }
  //-------- en formato 2023-02-08----------------------------------------------
  static fechaActualStr(){
    const today  = new Date();
    const dia = cApi.zeroFill( today.getDate(),2);
    const mes = cApi.zeroFill( today.getMonth() + 1, 2);
    const anio = today.getFullYear();
    const fechaHoy =  `${anio}-${mes}-${dia}`;
    return fechaHoy;
  }
  //-----------------
  //------convertir a formato ---------2023-02-22--------------------------------
  static toDateMy(fecha){
    const today  = new Date(fecha);
    const dia = cApi.zeroFill( today.getDate(),2);
    const mes = cApi.zeroFill( today.getMonth() + 1, 2);
    const anio = today.getFullYear();
    const fechaHoy =  `${anio}-${mes}-${dia}`;
    return fechaHoy;
  }
  //---2023-02-22--
  static strToDate(fechaStr){
    //var date3 = new Date("19-05-2022");
    if(fechaStr===undefined){
      return;
    }
   
    fechaStr = fechaStr.substring(0, 9);
    //console.log("fechastr:" + fechaStr);
    //-- var parts ='2014-04-03'.split('-');
    let parts = fechaStr.split('-');
    var mydate = new Date(parts[0], parts[1] - 1, parts[2]); 
    return mydate;
  }
  //-----------------
  static toTimeHHMM(dateObj){
    //let dateObj = new Date(given_seconds * 1000);
      let hours = dateObj.getHours();
      let minutes = dateObj.getMinutes();
      let seconds = dateObj.getSeconds();
  
      /*const timeString = hours.toString().padStart(2, '0')
                + ':' + minutes.toString().padStart(2, '0')
                + ':' + seconds.toString().padStart(2, '0');*/
      const timeString = hours.toString().padStart(2, '0')
                + ':' + minutes.toString().padStart(2, '0')
      return timeString;

  }
  //---------------------------
  // [{"value":1, "label":"Miércoles"}, {2, Jueves}, ...]
  static arrayDiasMes(mes, anyo){
    let date = new Date();
    let primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    //document.write("<br>El primer día es: "+primerDia.getDate());
    //document.write("<br>El ultimo día es: "+ultimoDia.getDate());
    //---generamos el array
    const array = [];
    for(let i=1; i<ultimoDia.getDate(); i++){
      
      // Ojo, hay que restarle 1 para obtener el mes correcto
      let fecha = new Date(anyo, mes - 1, i).getDay();
      let obj = {"value":i, "label": cUtilFecha.dia_literal(fecha)};
      array.push(obj);
    }

    return array;
  }
  //------------dia literal de una fecha
  // fecha es Date
  // The getDay() method returns the day of the week (0 to 6) of a date.
  static dia_literal( fecha_cad ){
    const dias = [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ];
    const posDia = new Date(fecha_cad).getDay();
    //const posDia = fecha.getDay();
    const nombreDia = dias[posDia];
    return nombreDia;
  }
  static dia_literal_peq( fecha ){
    const dias = [
      'Dom',
      'Lun',
      'Mar',
      'Miér',
      'Jue',
      'Vier',
      'Sáb',
    ];
    //const posDia = new Date(fecha_cad).getDay();
    const posDia = fecha.getDay();
    const nombreDia = dias[posDia];
    return nombreDia;
  }
  //----------------------------------------------
  static mes_literal(mes, anyo) {
    mes = mes.toUpperCase();
    var meses = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    return new Date(anyo, meses.indexOf(mes) + 1, 0).getDate();
  }
  static mes_To_literal(mes) {
    //mes = mes.toUpperCase();
    let meses = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    return (meses[mes+1]);
  }
  //-----------------------------------
  static mes_literal_peq(mes_number) {
    let meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    return meses[mes_number];
  }
  //-------------- 
  static mes_anterior(fecha) {
    const aux = fecha;
    let fecha_new  = new Date( aux.setMonth( aux.getMonth()-1 ));
    return fecha_new;
  }
  //-------------- 
  static mes_siguiente(fecha) {
    //const aux = new Date(fecha_cad);
    let aux = fecha;
    //console.log("fecha:"+ fecha);
    let fecha_new  = new Date(aux.getFullYear(), aux.getMonth()+1, aux.getDate());
    
    return fecha_new;
  }
  //-----------------
  //----- para restar se coloca -1 en days
  static addDays(fecha, days) {
    const date = new Date(fecha);
    //const date = fecha;
    //console.log("antes "+ date.getDate());
    date.setDate(date.getDate() + days);
    //console.log("despues "+ date);
    return date;
  }
  //-------------- 
  static addMonths(fecha, cant) {
    //const date = new Date(fecha);
    let aux = fecha;
    //const date = fecha;
    let fecha_new  = new Date(aux.getFullYear(), aux.getMonth()+ cant, aux.getDate());
    return fecha_new;
  }
  //----- para restar se coloca -1 en days
  static addYears(fecha, anyos) {
    const date = new Date(fecha);
    //const date = fecha;
    date.setFullYear(date.getFullYear()+ anyos);
    return date;
  }
  //------------------------------------
  static addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
  }
  //-------------inicio de semana domingo---------------------
  static startOfWeek ( fecha ) {
    //--inicio de semana en domingo
    //var curr = new Date;
    //let curr = fecha;
    const curr = new Date(fecha); 
    let firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    return firstday;
  }
  //----------- inicio de semana lunes
  static startOfWeekMonday ( fecha_ ) {
    const fecha = new Date(fecha_);  
    //-- inicio de semana en lunes
    return (new Date(fecha.setDate(fecha.getDate() - fecha.getDay()+ (fecha.getDay() == 0 ? -6:1) )));
  }
  //---------- inicio de semana toma 0 sunday, 1 monday -----
  //- const fechai = cUtilFecha.startOfWeekEx(fechaNew,1);
  /*static startOfWeekEx(dateObject, firstDayOfWeekIndex) {

    const dayOfWeek = dateObject.getDay();
    const firstDayOfWeek = new Date(dateObject);
    const diff = dayOfWeek >= firstDayOfWeekIndex ? dayOfWeek - firstDayOfWeekIndex : 6 - dayOfWeek

    firstDayOfWeek.setDate(dateObject.getDate() - diff)
    firstDayOfWeek.setHours(0,0,0,0)

    return firstDayOfWeek
  }*/
  //----------fin de semana--------------------------
  static endOfWeek ( fecha ) {
    //const curr = fecha;
    //esto porque setDate modifica el valor, y como es ref modifica el estado
    const curr = new Date(fecha);  
    //--- inicio de semana en domingo
    let lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+6));
    return lastday;
  }
  //--- ultimo dia de la semana tomando lunes como inicio de semana
  static endOfWeekMonday ( fecha_ ) {
    //--- inicio de semana en lunes
    const fecha = new Date(fecha_); 
    let lastday = new Date(fecha.setDate(fecha.getDate() - fecha.getDay() +7));
    return lastday;
  }
  //---------------
  static nextWeek ( fecha_ ) {
    //var curr = new Date;
    //var today = fecha;
    const today = new Date(fecha_); 
    let nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
    return nextweek;
  }
  static prevWeek ( fecha ) {
    //var curr = new Date;
    //const now = fecha;
    const now = new Date(fecha); 
    let datex = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
    return datex;
  }
  //------------------------------
  // - devuelve el comienzo de un mes ejemplo entra: 15/5/2022 sale 1/5/2022
  static startOfMonth(date)
  {
   return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  //devuelve el final de un mes de una fecha determinada
  //Setting day parameter to 0 means one day less than first day of the month which is last day of the previous month.
  static endOfMonth(date){
    //let today = new Date();
    //let today = date;
    const today = new Date(date); 
    let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
    return lastDayOfMonth;

  }
  //devuelve verdadero si las fechas, tienen el mismo mes (y año)
  static isSameMonth(dateA, dateB){
    return (
      dateA?.getFullYear() == dateB?.getFullYear() &&
      dateA?.getMonth() == dateB?.getMonth()
    )
  }
  //Devuelve verdadero si los dos objetos DateTime tienen el mismo día, mes y año, o si ambos son nulos.
  static isSameDay(dateA, dateB){
    return (
      dateA?.getFullYear() == dateB?.getFullYear() &&
      dateA?.getMonth() == dateB?.getMonth() &&
      dateA?.getDate() == dateB?.getDate()
    )
  }
  //----------------------------------
  static format(date, dateFormat)
  {
    let sol="";
    switch(dateFormat){
      case "D": sol = new Intl.DateTimeFormat("es",{ day: "numeric" }).format( date ); 
                break;
      case "dddd": sol = new Intl.DateTimeFormat("es",{ weekday: "long" }).format( date ); 
                break;
                
      default :break;
    }
   
    return sol;
  }
  //------------------ en espeñol -----------
  // ops = 0 minusculas
  // 1 mayusculas
  // 2 primera mayuscula
  static get_nombre_mes (numero, ops=1) {
    let miFecha = new Date();
    let sol="";
    if (0 < numero && numero <= 12) {
      miFecha.setMonth(numero - 1);
      sol = new Intl.DateTimeFormat('es-ES', { month: 'long'}).format(miFecha);
    } else {
      return null;
    }

    //
    switch (ops){
      case 0: break;
      case 1: sol = sol.toUpperCase(); break;
      case 2: sol = sol.charAt(0).toUpperCase() + sol.slice(1);//primera letra mayusculas
              break
      default: break;
    }
    return sol;
  }

}

export default cUtilFecha;