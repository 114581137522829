<template>
  <teleport to="body">
    <focus-trap v-model:active="active">
      <div
          ref="modal"
          class="modal fade"
          :class="{ show: active, 'd-block': active }"
          tabindex="-1"
          role="dialog"
          :aria-labelledby="`modal-${id}`"
          :aria-hidden="active"
      >
        <div class="modal-dialog modal-dialog-centered" role="document" >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-dark" :id="`modal-${id}`"><slot name="title"></slot></h5>
              <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="close();"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-dark">
              <slot></slot>
            </div>
            <!--<div class="modal-footer text-dark">
              <button type="button" class="btn btn-danger" @click="context.emit('closeModal', true)">Yes</button>
              <button type="button" class="btn btn-success" @click="context.emit('closeModal', false)">No</button>
            </div>-->
          </div>
        </div>
      </div>
    </focus-trap>
    <div class="fade" :class="{ show: active, 'modal-backdrop show': active }"></div>
  </teleport>
</template>

<script>

import { ref, watch} from 'vue'
//import IdUnique from '../js/utilities/utilities-unique-id';
import { FocusTrap } from 'focus-trap-vue'

export default {
  name: 'Modal',
  emits: ['closeModal'],
  components: {
    FocusTrap: FocusTrap
  },
  props: {
    showModal: Boolean,
    modalId: String,
  },
  setup(props, context){
    const id = 5;// IdUnique();
    const active = ref(props.showModal);

    function close() {
        //--evento hacia afuera
        context.emit('closeModal');
    }

    watch(() => props.showModal, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        active.value = props.showModal;
        const body = document.querySelector("body");
        props.showModal ? body.classList.add("modal-open") : body.classList.remove("modal-open")
      }
    },{immediate:true, deep: true});

    return {
      active,
      id,
      close,
    }
  }
}
</script>