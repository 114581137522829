import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "../store/index";
import axios from 'axios';

import HomeView from '../views/HomeView.vue'

//----
import Login from '../pages/Login.vue'
import Home from '../pages/Home.vue'
import About from '../pages/About.vue'
import Main from '../pages/Main.vue'

import Plan_crud from '../pages/mantenimiento/plan_cuentas/Plan_crud.vue'
import Plan_nuevo from '../pages/mantenimiento/plan_cuentas/Plan_nuevo.vue'

import Asiento_crud from '../pages/mantenimiento/asientos/Asiento_crud.vue'
import Asiento_nuevo from '../pages/mantenimiento/asientos/AsientoNuevo.vue'

import Rep_libro_diario from '../pages/reportes/Rep_libro_diario.vue'
import Rep_mayores from '../pages/reportes/Rep_mayores.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Login,
  },
  //{ path: '/', name:'login', meta: { layout:"VoidLayout",requiredAuth: false  }, component: Login },
  //{ path: '/', name:'login', component: Login },
  { path: '/login', name:'login', meta: { layout:"VoidLayout",requiredAuth: false  }, component: Login },

  { path: '/about',   name: 'about',   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')  },  

  { path: '/home', name: 'Home', meta: { layout:"AdminLte" ,requiredAuth: true }, component: Home },
  { path: '/about', name: 'About', meta: { layout:"NoSidebar",requiredAuth: true }, component:About},
  { path: '/main', name: 'main', meta: { layout:"NoSidebar",requiredAuth: true }, component:Main},

  { path: '/plan_cr', name: 'plan_cr', meta: { layout:"AdminLte", requiredAuth: true }, component: Plan_crud },
  { path: '/plan_nuevo/:id/:ope', name: 'plan_nuevo', meta: { layout:"AdminLte", requiredAuth: true }, component: Plan_nuevo },

  { path: '/asientos', name: 'asientos', meta: { layout:"AdminLte", requiredAuth: true }, component: Asiento_crud },
  { path: '/asiento_nuevo/:idd/:ope', name: 'asiento_nuevo', meta: { layout:"AdminLte", requiredAuth: true }, component: Asiento_nuevo },

  { path: '/libro_diario', name: 'libro_diario', meta: { layout:"AdminLte", requiredAuth: true }, component: Rep_libro_diario },
  { path: '/rep_mayores', name: 'rep_mayores', meta: { layout:"AdminLte", requiredAuth: true }, component: Rep_mayores },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;

//-----------------------------------------------------------------
router.beforeEach(async (to, from, next) => {
  //console.log(store.getters["auth/getAuthData"].token);
  if (!store.getters["auth/getAuthData"].token) {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (access_token) {
      const data = {
        access_token: access_token,
        refresh_token: refresh_token,
      };
      store.commit("auth/saveTokenData", data);
    }
  }
  //---si token es activo
  let auth = store.getters["auth/isTokenActive"];
  console.log("auth:"+auth);
  console.log("to.meta.requiredAuth:"+to.meta.requiredAuth);
  
  /* esto he añadido, jimmy */
  if(!auth && !to.meta.requiredAuth){
    //return next({ path: "/login" });
    return next();
  }
  
  //console.log("paso 2");
  if (!auth) {
    const authData = store.getters["auth/getAuthData"];
    //console.log(authData.token);

    if (authData.token) {
      const payload = {
        access_token: authData.token,
        refresh_token: authData.refresh_token,
      };
      //"http://localhost:3000/auth/refreshtoken",
      const refreshResponse = await axios.post(process.env.VUE_APP_API_URL + "refresh", payload);
      store.commit("auth/saveTokenData", refreshResponse.data);
      auth = true;
    }else{
      //console.log("else");
      //----jimmy
      //return next({path: "/"});  //   /login
      return next({path: "/login"});  //   /login
    }
  }

  /*if (to.fullPath == "/") {
    return next();
  } else if (auth && !to.meta.requiredAuth) {
    return next({ path: "/dashboard" });
  } else if (!auth && to.meta.requiredAuth) {
    return next({ path: "/login" });
  }*/

  return next();
});
