<template>
    <div class="hello">
        <div class="m-0 vh-100 row justify-content-center ">
            <div class="col-auto p-5 text-center">
                
                <!-- tab-->
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#tab01" type="button" role="tab" aria-controls="tab01" aria-selected="true">Principal</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#tab02" type="button" role="tab" aria-controls="tab02" aria-selected="false">Admin</button>
                    </li>
                    <!--<li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#tab03" type="button" role="tab" aria-controls="tab03" aria-selected="false">Admin</button>
                    </li>-->
                </ul>
                <!-- contenido tab-->
                <div class="tab-content" id="myTabContent" style="padding:10px" >
                    <div class="tab-pane fade show active" id="tab01" role="tabpanel" aria-labelledby="home-tab">

                        <!--iconos -->
                        <div class="row " style=" max-width: 700px;">
                            <div class="col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/guarantee.png" alt="">
                                        <h4>Nuevo certificado </h4>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/m_plantilla1.png" alt="">
                                        <h4>Imprimir sobre plantilla </h4>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/m_plantilla2.png" alt="">
                                        <h4>Imprime sobre plantilla 2 </h4>
                                    </span>
                                </div>
                            </div>
                            <div class=" col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/m_plantilla3.png" alt="">
                                        <h4>Imprimir sobre plantilla 3</h4>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/m_preimpreso.png" alt="">
                                        <h4>Imprimir sobre preimpreso</h4>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/m_browser.png" alt="">
                                        <h4>Browser</h4>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <router-link to="/menu_mante">
                                    <div class="panel-bx">
                                        <span class="panel-icon">
                                            <img src="~@/assets/img/m_ensayes.png" alt="">
                                            <h4>Ensayes</h4>
                                        </span>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/m_detalle.png" alt="">
                                        <h4>Detalle de ensayes</h4>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/m_resumen.png" alt="">
                                        <h4>Resumen general</h4>
                                    </span>
                                </div>
                            </div>
                            

                            <!-- tag -->
                            <div class="col-md-12">
                                <h6> <b>Info:&nbsp;</b>Los permisos a las opciones las brinda el usuario administrador
                                </h6>
                            </div>
                        </div>
                    </div>
                    <!-- segundo tab ----------------------------------->
                    <div class="tab-pane fade" id="tab02" role="tabpanel" aria-labelledby="profile-tab">

                        <div class="row " style=" max-width: 700px;">
                            <div class="col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/m_elementos.png" alt="">
                                        <h4>Elementos </h4>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <router-link to="/cliente_cr">
                                        <span class="panel-icon">
                                            <img src="~@/assets/img/m_clientes.png" alt="">
                                            <h4>Clientes </h4>
                                        </span>
                                    </router-link>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <router-link to="/usuario_cr">
                                        <span class="panel-icon">
                                            <img src="~@/assets/img/m_usuarios.png" alt="">
                                            <h4>Usuarios</h4>
                                        </span>
                                    </router-link>
                                </div>
                            </div>
                            <div class=" col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/m_empresa.png" alt="">
                                        <h4>Empresa</h4>
                                    </span>
                                </div>
                            </div>

                            <div class=" col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/m_config.png" alt="">
                                        <h4>Configuración</h4>
                                    </span>
                                </div>
                            </div>
                            <div class=" col-md-3 col-sm-12">
                                <div class="panel-bx">
                                    <span class="panel-icon">
                                        <img src="~@/assets/img/m_acerdade.png" alt="">
                                        <h4>Acerca de</h4>
                                    </span>
                                </div>
                            </div>
                        </div>


                    </div>

                    <!-- tercer tab -->
                    <!--<div class="tab-pane fade" id="tab03" role="tabpanel" aria-labelledby="contact-tab">.d..</div>-->
                </div>

            </div>
        </div>
        

    </div>
</template>
<script>
export default {
  props: {
    msg: String
  },
  setup(){

    return{

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
